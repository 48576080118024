<template>
  <div class="gps-track">
    <div v-bind:class="mobileMenuActive ? 'block' : 'hidden'" @click="closeMenu()" class="fixed z-20 inset-0 bg-black opacity-50 transition-opacity lg:hidden"></div>
    <div v-show="tokenValid" class="flex flex-wrap unit-gps-wrapper">
      <div class="w-full lg:w-5/6 map-container">
        <GmapMap :center="center" :zoom="zoom" map-type-id="terrain" class="google_map_wrapper_alarm" ref="map">
          <GmapCluster>
            <GmapInfoWindow :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen = false"> </GmapInfoWindow>
            <GmapMarker v-for="(m, index) in markers" :position="m.position" :clickable="true" :draggable="false" @mouseover="toggleInfoWindow(m, index)" @mouseout="toggleInfoWindow(m, index)" :key="index" :icon="{ url: m.icon }"></GmapMarker>
          </GmapCluster>
          <GmapCircle v-if="alarmCircle" :center="alarmCircle.center" :radius="50" :visible="true" :options="{ fillColor: '#FF0000', fillOpacity: 0.5, strokeColor: '#FF0000', strokeOpacity: 0.8, strokeWeight: 2 }"></GmapCircle>
        </GmapMap>
      </div>
      <transition enter-active-class="transition ease-out duration-100" enter-class="transform translate-x-2 opacity-0" enter-to-class="transform translate-x-0 opacity-100" leave-active-class="transition ease-in duration-75" leave-class="transform translate-x-0 opacity-100" leave-to-class="transform translate-x-2 opacity-0">
        <div class="absolute top-0 right-0 min-h-screen w-4/6 md:w-2/6 lg:min-h-full lg:w-1/6 bg-white position-inherit z-30 border border-secondary-300" v-if="mobileMenuActive">
          <div class="alarm-item hover:bg-primary-50" v-if="alarm">
            <div @click="focusAlarm(alarm.imei_number)" class="alarm-box cursor-pointer py-4 px-4" v-bind:class="{ 'bg-red-500 hover:bg-red-500 triggered': alarm.pushButton }">
              <div class="item text-sm text-primary-500 font-bold pb-2">{{ alarm.unit_name }}</div>
              <div class="item text-xxs"><span class="last-seen mr-1" v-bind:class="{ inactive: alarm.lastSeenTime > 1000 * 60 * 60 }">⬤</span>{{ alarm.lastSeen }}</div>
              <div class="item text-sm pb-3 flex flex-wrap mt-3">
                <span class="mr-2 p-1 bg-white border rounded h-7" v-if="checkIOS()">
                  <a :href="generateMapLink('apple', gps_alarm.gps_pos)" target="_blank">
                    <img :src="require('@/assets/icon/apple-map-pin.png')" class="w-4" />
                  </a>
                </span>
                <span class="mr-2 p-1 bg-white border rounded h-7">
                  <a :href="generateMapLink('google', gps_alarm.gps_pos)" target="_blank">
                    <img :src="require('@/assets/icon/google-maps.png')" class="w-4" />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div class="py-10 text-center" v-show="tokenValid == false">
      <div><BaseIcon icon="exclamation-triangle" class="text-red-500 text-4xl" /></div>
      <div class="mt-3 text-2xl">Länken har upphört att gälla</div>
    </div>

    <AlarmGPSInfo :alarmInfo="infoSRT326" />
  </div>
</template>

<script>
import AlarmGPSInfo from "@/components/modal/alarm_gps_info";
import { gmapApi } from "vue2-google-maps";
import "@googlemaps/markerclustererplus";

export default {
  name: "GPSTrackAlarm",
  title() {
    return `Track larm | SecurCloud`;
  },
  components: {
    AlarmGPSInfo,
  },
  data() {
    return {
      token: this.$route.params.token,
      tokenValid: null,
      isMobile: false,
      mobileMenuActive: false,
      mapFullScreenControl: true,
      alarm: null,
      gps_alarm: null,
      zoom: this.$store.state.mapZoom,
      center: this.$store.state.mapCenter,
      markers: [],
      refreshIntervalRef: null,
      refreshInterval: 30000, // every 30s
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: { content: "", pixelOffset: { width: 0, height: -35 } },
      globalPolygonOptions: {
        strokeColor: "#ff1200",
        strokeOpacity: 0.6,
        strokeWeight: 2,
        fillColor: "#ff1200",
        fillOpacity: 0.3,
        editable: false,
        draggable: false,
      },
      alarmCircle: null,
      firstLoad: true,
      infoSRT326: null,
    };
  },
  methods: {
    refreshGPSData() {
      this.refreshIntervalRef = setInterval(() => {
        if (this.alarm && this.tokenValid) this.getGPSAlarm();
      }, this.refreshInterval);
    },

    getGPSAlarm() {
      var vm = this;

      // todo: security. restrict what data is sent through response

      this.axios
        .get(`${process.env.VUE_APP_SERVER_URL}/gps/alarm/${this.token}`)
        .then((response) => {
          if (response.data.success) {
            this.tokenValid = true;

            this.alarm = response.data.alarm;
            this.gps_alarm = response.data.gps_alarm;
            this.generateMobileMenu();

            if (this.gps_alarm) {
              let lastSeenObj = this.getAlarmLastSeenTime();
              this.alarm.lastSeen = lastSeenObj.lastSeen;
              this.alarm.lastSeenTime = lastSeenObj.lastSeenTime;

              this.alarm.speed = this.gps_alarm.alarm_speed;
              if (new RegExp(/^SRT/g).test(this.alarm.type)) this.alarm.speed = (this.gps_alarm.alarm_speed * 1.852).toFixed(2);

              this.alarm.batteryPercent = this.gps_alarm.battery_percent;
              if (new RegExp(/^SRT/g).test(this.alarm.type)) this.alarm.batteryPercent = Math.min(Math.floor(this.gps_alarm.battery_voltage * 100 - 323), 100);

              this.alarm.pushButton = false;
              if (this.gps_alarm.lastPushButtonTimestamp && new Date().getTime() - this.gps_alarm.lastPushButtonTimestamp < 1800000) this.alarm.pushButton = true;
            }

            // alarm marker
            if (this.alarm.type != "SRT326") {
              this.markers = [];

              let icon = require("@/assets/icon/new_small_map_marker.png");
   
              if (this.alarm.type == "SRT430") icon = require("@/assets/icon/small_SRT430.png");
              if (this.alarm.type == "SRT341") icon = require("@/assets/icon/srt341.png");
              if (this.alarm.type == "SRT406") icon = require("@/assets/icon/small_SRT406.png");
              if (this.alarm.type == "SRT406i") icon = require("@/assets/icon/small_SRT406.png");
              if (new RegExp(/^TELTONIKA/g).test(this.alarm.type)) icon = require("@/assets/icon/car.png");
  
              else if (new RegExp(/^TWIG/g).test(this.alarm.type)) {
                icon = require("@/assets/icon/twig_map_marker.png");

                if (this.alarm.type == "TWIG One EX") icon = require("@/assets/icon/twig_one_ex.png");
                else if (/^TWIG One/.test(this.alarm.type)) icon = require("@/assets/icon/twig_one.png");
              }

              this.markers.push({
                icon: icon,
                position: { lat: this.gps_alarm.gps_pos.lat, lng: this.gps_alarm.gps_pos.lng },
                infoText: `${this.$t("map_info_popup.alarm_name")}: <b>${this.alarm.unit_name}</b><br>
                        ${this.$t("map_info_popup.gsm_signal_strength")}: <b>${this.getGSMSignalPercent(this.gps_alarm.gsm_signal)}</b><br>
                        ${this.$t("map_info_popup.battery_percentage")}: <b>${this.alarm.batteryPercent} %</b><br>
                        ${this.$t("map_info_popup.speed")}: <b>${this.alarm.speed} km/hr</b><br>
                        ${this.$t("map_info_popup.lat")}: <b>${this.gps_alarm.gps_pos.lat.toFixed(3)}</b>, Lng: <b>${this.gps_alarm.gps_pos.lng.toFixed(3)}</b>
                        `,
                imei_number: this.alarm.imei_number,
              });

              // alarm trigger circle
              this.alarmCircle = null;
              if (this.gps_alarm.lastPushButtonTimestamp && new Date().getTime() - this.gps_alarm.lastPushButtonTimestamp < 1800000) {
                this.alarmCircle = {
                  center: { lat: this.gps_alarm.gps_pos.lat, lng: this.gps_alarm.gps_pos.lng },
                };
              }
            }

            if (this.firstLoad) {
              this.firstLoad = false;
              vm.$refs.map.$mapPromise.then(() => {
                // eslint-disable-line
                vm.focusAlarm(this.alarm.imei_number);
              });
            }

            if (document.hasFocus()) this.handleSuccess("Plats uppdaterad", "top-right", 1500);
          } else {
            this.tokenValid = false;
          }
        })
        .catch((error) => {
          this.tokenValid = false;
          this.handleError(error, "");
        });
    },

    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoOptions.content = marker.infoText;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    focusAlarm(imei) {
      var vm = this;

      let markerIndex = _.findIndex(this.markers, { imei_number: imei });

      if (markerIndex >= 0) {
        vm.$refs.map.$mapObject.panTo(vm.markers[markerIndex].position);
        vm.$refs.map.$mapObject.setZoom(16);

        this.$nextTick().then(() => {
          vm.toggleInfoWindow(this.markers[markerIndex], markerIndex);
        });
      } else {
        this.showSRT326({
          alarm_name: this.alarm.unit_name,
          battery: Math.min(Math.floor(this.gps_alarm.battery_voltage * 100 - 323), 100),
          gsm_signal: this.getGSMSignalPercent(this.gps_alarm.gsm_signal),
        });
      }

      if (this.isMobile) this.mobileMenuActive = false;
    },

    showSRT326(alarm) {
      this.infoSRT326 = alarm;
      this.$modal.show("modal-info-srt326");
    },

    getGSMSignalPercent(g) {
      // conversion as per SRT documentation
      if (g <= 31) return Math.ceil(3.225 * g) + " %";
      else return "N/A";
    },

    getAlarmLastSeenTime() {
      return {
        lastSeen: this.moment(this.gps_alarm.updatedAt).fromNow(),
        lastSeenTime: this.moment().diff(this.moment(this.gps_alarm.updatedAt)),
      };
    },

    checkMobile() {
      var vm = this;

      this.isMobile = window.innerWidth < 1024;

      if (this.isMobile) {
        vm.mobileMenuActive = false;
        vm.mapFullScreenControl = false;
      } else {
        this.mobileMenuActive = true;
        this.mapFullScreenControl = true;
      }
    },

    closeMenu() {
      this.mobileMenuActive = false;
    },

    generateMobileMenu() {
      var vm = this;

      let controlDiv = this.generateGoogleMapActionDiv();
      this.mobileMenuDiv = controlDiv;

      let googleMapIcon = vm.generateGoogleMapIcon("google", vm.gps_alarm.gps_pos);
      let appleMapIcon = vm.generateGoogleMapIcon("apple", vm.gps_alarm.gps_pos);

      controlDiv.addEventListener("click", () => {
        vm.mobileMenuActive = true;
      });

      vm.$refs.map.$mapPromise.then((map) => {
        if (map.controls[vm.google.maps.ControlPosition.TOP_RIGHT].length === 0) {
          map.controls[vm.google.maps.ControlPosition.TOP_RIGHT].push(this.mobileMenuDiv);
          map.controls[vm.google.maps.ControlPosition.TOP_RIGHT].push(googleMapIcon);
          if (vm.checkIOS()) {
            map.controls[vm.google.maps.ControlPosition.TOP_RIGHT].push(appleMapIcon);
          }
        }
      });
    },
    generateGoogleMapIcon(map_type, gps_pos) {
      let controlDiv = document.createElement("div");
      controlDiv.className += "lg:hidden";

      const controlUI = document.createElement("div");
      controlUI.style.backgroundColor = "#fff";
      controlUI.style.borderRadius = "2px";
      controlUI.style.boxShadow = "0 2px 6px rgba(0,0,0,.3)";
      controlUI.style.cursor = "pointer";
      controlUI.style.marginTop = "10px";
      controlUI.style.marginBottom = "15px";
      controlUI.style.marginRight = "8px";
      controlUI.style.padding = "5px";
      controlUI.style.textAlign = "center";
      controlUI.style.height = "41px";

      // Create and append new elements
      const spanElement = document.createElement("span");
      spanElement.className = "mr-2";
      spanElement.textContent = "";

      const anchorElement = document.createElement("a");

      anchorElement.href = this.getMapLink(map_type, gps_pos);
      anchorElement.target = "_blank";

      const imgElement = document.createElement("img");
      let iconUrl = map_type === "google" ? "google-maps.png" : "apple-map-pin.png";
      imgElement.src = require("../assets/icon/" + iconUrl);
      imgElement.className = "mt-1";
      anchorElement.appendChild(imgElement);
      spanElement.appendChild(anchorElement);

      controlUI.appendChild(spanElement);
      controlDiv.appendChild(controlUI);

      return controlDiv;
    },
    generateMapLink(mapType, gps_pos) {
      return this.getMapLink(mapType, gps_pos);
    },
  },

  computed: {
    google: gmapApi,
  },

  created() {
    this.getGPSAlarm();
    window.addEventListener("resize", this.checkMobile);
  },

  mounted() {
    this.refreshGPSData();

    this.checkMobile();
  },

  beforeDestroy() {
    clearInterval(this.refreshIntervalRef);
    window.removeEventListener("resize", this.checkMobile);
  },
};
</script>
